/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Source+Sans+3:wght@200;300;400;600;700&display=swap');*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
}

.app{
    display: flex;
    position: relative;
}
::-webkit-scrollbar{
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track{
    background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb{
    background: #888888;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover{
    background: #555555;
}

.imgMarginTop{
    margin-top: 10px
}